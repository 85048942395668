import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingOrderGuard } from './modules/site/services/pending-order.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/authentication/authentication.module')
        .then((m) => m.AuthenticationModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./modules/registration/registration.module')
        .then((m) => m.RegistrationModule),
  },
  {
    path: 'portal',
    loadChildren: () =>
      import('./modules/site/site.module')
        .then((m) => m.SiteModule),
  },
  {
    path: 'debtors/new',
    canActivate: [PendingOrderGuard],
    loadChildren: () =>
      import('./modules/new-debtor-test/new-debtor-test.module')
        .then((m) => m.NewDebtorTestModule),
  },
  {
    path: 'insurances/new',
    canActivate: [PendingOrderGuard],
    loadChildren: () =>
      import('./modules/new-insurance/new-insurance.module')
        .then((m) => m.NewInsuranceModule),
  },
  {
    path: 'debtors/:id/claim',
    canActivate: [PendingOrderGuard],
    loadChildren: () =>
      import('./modules/new-insurance-claim/new-insurance-claim.module')
        .then((m) => m.NewInsuranceClaimModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
