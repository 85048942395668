import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { InputComponentsModule } from "../../input/input-components.module";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { HeaderComponent } from './header/header.component';
import { GraphicComponentsModule } from "../../graphic/graphic.module";
import { Trade4SureCoreComponentsModule } from "@trade4sure/t4s-lib";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        SideMenuComponent,
        HeaderComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        InputComponentsModule,
        GraphicComponentsModule,
        NgbModule,
    ],
    exports: [
        SideMenuComponent,
        HeaderComponent,
        Trade4SureCoreComponentsModule
    ]
})
export class CoreSiteComponentsModule { }
