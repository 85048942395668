import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { RedirectButton, SubscriptionSolver } from '@trade4sure/t4s-lib';
import { debounceTime, filter } from 'rxjs';
import {
  HeaderService,
  InputFilters,
  OutputFilters,
} from 'src/app/modules/site/services/header.service';
import { ExportService } from 'src/app/modules/site/services/export.service';
import { CustomerUserControllerService } from 'src/app/modules/api-client/services/user/customer-user/customer-user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends SubscriptionSolver {
  public title = '';
  public redirectButton?: RedirectButton;
  public activatedFilter: string | undefined;
  public showSearchField = true;
  public showExportButton = true;

  private _filters: InputFilters = {};
  private currentFilters: OutputFilters;

  public get filters(): string[] {
    return Object.keys(this._filters);
  }

  constructor(
    private titleService: Title,
    private router: Router,
    private headerService: HeaderService,
    public customerUserService: CustomerUserControllerService,
    public exportService: ExportService
  ) {
    super();
    this.currentFilters = {};
    this.title = this.titleService.getTitle();
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          debounceTime(200)
        )
        .subscribe((_) => (this.title = this.titleService.getTitle())),

      this.headerService.inputFilters.subscribe((x) => {
        this._filters = x;
        this.currentFilters = {
          term: '',
        };

        this.activatedFilter = this.headerService.currentFilter
          ? Object.keys(x).find((y) => y === this.headerService.currentFilter)
          : Object.keys(x).find((y) => y);
        if (this.activatedFilter) {
          this.currentFilters[x[this.activatedFilter].key] =
            x[this.activatedFilter].value;
        }
        this.headerService.outputFilters.next(this.currentFilters);
      }),

      this.headerService.redirectButton.subscribe(
        (x) => (this.redirectButton = x)
      ),

      this.headerService.showSearchField.subscribe(
        (x) => (this.showSearchField = x)
      ),

      this.headerService.showExportButton.subscribe(
        (x) => (this.showExportButton = x)
      ),
    );
  }

  public onExportClicked() {
    this.exportService.executeExport();
  }

  public search(term: string): void {
    this.currentFilters['term'] = term;
    this.headerService.outputFilters.next(this.currentFilters);
  }

  public filter(filterName: string): void {
    const term = this.currentFilters['term'];
    const filter = this._filters[filterName];
    this.currentFilters = { term };
    this.currentFilters[filter.key] = filter.value;
    this.headerService.outputFilters.next(this.currentFilters);
  }

  public onRedirectButtonClick(): void {
    if (this.redirectButton?.route) {
      this.router.navigate([this.redirectButton?.route]);
      return;
    }

    if (this.redirectButton?.callback) this.redirectButton.callback();
  }
}
