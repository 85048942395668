import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss']
})
export class CircleComponent {
  @Input() number = 1;
  @Input() borderSize = 2;
  @Input() textSize = 16;
  @Input() activated = false;
  @Input() done = false;

  public get isDoneOrActivated(): boolean {
    return this.activated || this.done;
  }
}
