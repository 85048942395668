import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CircleComponent } from "./circle/circle.component";
import { StepComponent } from "./step/step.component";
import { ProgressDotsComponent } from './progress-dots/progress-dots.component';
import { Trade4SureGraphicComponentsModule } from "@trade4sure/t4s-lib";

@NgModule({
    declarations: [
        CircleComponent,
        StepComponent,
        ProgressDotsComponent
    ],
    exports: [
        CircleComponent,
        StepComponent,
        ProgressDotsComponent,
        Trade4SureGraphicComponentsModule
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class GraphicComponentsModule { }
