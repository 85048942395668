<div class="modal-main">
    <div class="modal-header">
        <span class="title">{{ title }}</span>
    </div>
    <div class="modal-body">
        <div class="package-information">
            <span class="row">
                <span class="col-6 d-flex flex-column">
                    <span class="field-title">Huidig pakket</span>
                    <span class="field-value">
                        {{ user?.currentSubscriptionPurchase?.title ?? '' }}
                    </span>
                </span>
                <span class="col-6 d-flex flex-column">
                    <span class="field-title">Doorlooptijd</span>
                    <span class="field-value">
                        {{ (user?.currentSubscriptionPurchase?.startAt | date:'dd-MM-yyyy') + ' tot ' + (user?.currentSubscriptionPurchase?.endAt | date:'dd-MM-yyyy') }}
                    </span>
                </span>
            </span>
            <span class="row">
                <span class="col-6 d-flex flex-column">
                    <span class="field-title">Toetsingen</span>
                    <t4s-tag 
                        [text]="debtorTestsText"
                        [backgroundColor]="user?.currentSubscriptionPurchase?.isCancelled ? '--error-500' : ''"
                    >
                    </t4s-tag>
                </span>
                <span class="col-6 d-flex flex-column">
                    <span class="field-title">Debiteurenscan</span>
                        <t4s-tag *ngIf="showDebtorScanText" [text]="debtorScanText"></t4s-tag>
                        <span *ngIf="!showDebtorScanText" class="field-value">
                            {{ user?.currentSubscriptionPurchase?.amountOfDebtorScans | amountOfDebtorScans }}
                        </span>
                </span>
            </span>
        </div>
        <hr class="my-3">
        <ngb-alert *ngIf="packageChange?.type === PackageChangeType.Downgrade" type="downgrade" [dismissible]="false">
            <span class="material-symbols-outlined me-2">info</span>
            <span *ngIf="packageChange?.warning">
                U heeft momenteel teveel toetsingen om uw pakket om te zetten naar dit pakket. 
                U zult voor het einde van uw doorlooptijd een aantal toetsingen moeten stopzetten.
            </span>
            <span *ngIf="!packageChange?.warning">
                Wanneer het pakket word verlaagd zal dit ingaan op het einde van uw doorlooptijd. 
                Dit is {{ user?.currentSubscriptionPurchase?.endAt | date:'dd-MM-yyyy' }}.
            </span>
        </ngb-alert>
        <ngb-alert *ngIf="packageChange?.type === PackageChangeType.Upgrade" type="upgrade" [dismissible]="false">
            <span class="material-symbols-outlined me-2">info</span>
            <span>
                Bij verhoging zal het resterende bedrag automatisch geincasseerd worden. 
                Dit zijn de kosten van het nieuwe pakket minus de kosten van uw huidige pakket. 
                De kosten worden in rekening gebracht op de volgende rekening: {{ user?.iban }}
            </span>
        </ngb-alert>
        <t4s-switch
            *ngIf="canHaveMonthlyPrices && hasMonthlyPrices"
            class="mb-3"
            label="Betaal maandelijks"
            [(checked)]="monthlyPrices"
        ></t4s-switch>
        <t4s-subscription-tile
            *ngFor="let subscriptionProduct of subscriptionProducts; let isLast = last"
            (selectedChange)="selectPackage($event)"
            [subscriptionProduct]="subscriptionProduct"
            [currentSubscriptionPurchaseProductId]="currentSelectedPackage?.id"
            [selected]="currentSelectedPackage?.id === subscriptionProduct.id"
            [isCancelled]="user?.currentSubscriptionPurchase?.isCancelled ?? false"
            [isLast]="isLast"
            [preferredInterval]="getPreferredInterval()"
        ></t4s-subscription-tile>
        <a class="cancel-button" *ngIf="!user?.currentSubscriptionPurchase?.isCancelled" (click)="openCancelPackageModal()">Pakket opzeggen</a>
    </div>
</div>
<div class="modal-footer">
    <button (click)="activeModal.dismiss()"
        class="btn btn-outline-primary">
        {{ cancelButtonText }}
    </button>
    <t4s-save-button (clicked)="agree()"
        ngbAutoFocus
        [loading]="loading"
        [disabled]="!packageChange && !user?.currentSubscriptionPurchase?.isCancelled">
        {{ agreeButton.text }}
    </t4s-save-button>
</div>