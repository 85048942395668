import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Checkbox, CheckboxGroupOutput, LoadingIndicatorService } from '@trade4sure/t4s-lib';
import { CustomerUser } from 'src/app/modules/api-client/models/user/user';
import { OrderControllerService } from 'src/app/modules/api-client/services/order/order-controller.service';
import { CustomerUserControllerService } from 'src/app/modules/api-client/services/user/customer-user/customer-user.service';
import {
  OrderCancellationReason,
  OrderCancellationValue
} from '../shared/enums/modals.enum';
import { AgreeButton } from '../shared/interfaces/modals.interface';

@Component({
  selector: 'app-cancel-package-feedback-modal',
  templateUrl: './cancel-package-feedback-modal.component.html',
  styleUrls: [
    './cancel-package-feedback-modal.component.scss',
    '../shared/styles/modals.scss',
  ],
})
export class CancelPackageFeedbackModalComponent extends LoadingIndicatorService {
  public title = 'Pakket succesvol opgezegd';

  public agreeButton: AgreeButton = {
    text: 'Verstuur',
  };

  public cancelButtonText = 'Overslaan';

  public user: CustomerUser | undefined;

  public CheckboxGroupOutput = CheckboxGroupOutput;

  public reasonCheckboxes: Checkbox[] = [
    {
      label: 'Ik gebruik de product(en) niet vaak genoeg.',
      name: OrderCancellationReason.LittleUsage,
      value: OrderCancellationValue.LittleUsage,
      isChecked: false,
    },
    {
      label: 'Product(en) en functies zijn te ingewikkeld voor mij.',
      name: OrderCancellationReason.TooComplicated,
      value: OrderCancellationValue.TooComplicated,
      isChecked: false,
    },
    {
      label: 'Het is te duur.',
      name: OrderCancellationReason.TooExpensive,
      value: OrderCancellationValue.TooExpensive,
      isChecked: false,
    },
    {
      label: 'Ik heb te veel technische problemen.',
      name: OrderCancellationReason.TooManyTechnicalIssues,
      value: OrderCancellationValue.TooManyTechnicalIssues,
      isChecked: false,
    },
    {
      label: 'Anders (gelieve te specificeren).',
      name: OrderCancellationReason.Other,
      isChecked: false,
    },
  ];

  public form = new FormGroup({
    reason: new FormControl(0),
    description: new FormControl(''),
  });

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private customerUserService: CustomerUserControllerService,
    private orderService: OrderControllerService
  ) {
    super();
    this.subscriptions.push(
      this.customerUserService.currentUser.subscribe((x) => {
        this.user = x;
      })
    );
  }

  public skipCancelPackageFeedback(): void {
    this.activeModal.dismiss();
  }

  public sendCancelPackageFeedback(): void {
    if (this.user && this.form.dirty) {
      const id = this.user?.currentSubscriptionPurchase?.id;
      const orderId = this.user?.currentSubscriptionPurchase?.orderId;
      if (id && orderId) {
        this.executeLoadingCall(() => this.orderService
          .cancelOrderFeedback(orderId, id, {
            description: this.form.controls.description.value ?? '',
            reason: this.form.controls.reason.value ?? 0,
          }))
          .subscribe((x) => {
            this.activeModal.dismiss();
          });
      }
    }
  }
}
