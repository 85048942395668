<div class="d-flex">
    <div class="d-flex flex-column align-items-center wrapper"
        [ngClass]="{ 'activated': isDoneOrActivated }">
        <app-circle
            [number]="number"
            [activated]="activated"
            [done]="done"
        ></app-circle>
        <span *ngIf="!lastItem"
            class="divider"
            [ngClass]="{ 'activated-divider': done }"
        ></span>        
    </div>
    <span class="text"
        [ngClass]="{ 'activated-text': isDoneOrActivated }">
        {{ text }}
    </span>
</div>