import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent {
  @Input() lastItem = false;
  @Input() number = 1;
  @Input() text = "";
  @Input() activated = false;
  @Input() done = false;

  public get isDoneOrActivated(): boolean {
    return this.activated || this.done;
  }
}
