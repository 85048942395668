import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingIndicatorService } from '@trade4sure/t4s-lib';
import { CustomerUser } from 'src/app/modules/api-client/models/user/user';
import { OrderControllerService } from 'src/app/modules/api-client/services/order/order-controller.service';
import { CustomerUserControllerService } from 'src/app/modules/api-client/services/user/customer-user/customer-user.service';
import { AgreeButton } from '../shared/interfaces/modals.interface';

@Component({
  selector: 'app-reactivate-package-modal',
  templateUrl: './reactivate-package-modal.component.html',
  styleUrls: ['./reactivate-package-modal.component.scss', '../shared/styles/modals.scss']
})
export class ReactivatePackageModalComponent extends LoadingIndicatorService {
  @Input() title = 'Weet u het zeker?';
  @Input() agreeButton: AgreeButton = {
    text: 'Ja'
  };
  @Input() cancelButtonText = 'Annuleren';

  public user: CustomerUser | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private customerUserService: CustomerUserControllerService,
    private orderService: OrderControllerService
  ){
    super();
    this.subscriptions.push(
      this.customerUserService.currentUser
      .subscribe((x) => {
        this.user = x;
      })
    );
  } 

  public cancelPackageReactivation() {
    this.activeModal.dismiss();
  }

  public reactivatePackage() {
    const id = this.user?.currentSubscriptionPurchase?.id;
    const orderId = this.user?.currentSubscriptionPurchase?.orderId;
    if (id && orderId) {
      this.executeLoadingCall(() => this.orderService.reactivateOrder(orderId, id))
      .subscribe(() => {
        this.activeModal.dismiss();
      })
    }
  }
}
