import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  SubscriptionProduct,
  SubscriptionProducts,
} from '../../models/product/product';
import { ControllerBase } from '@trade4sure/t4s-lib';

@Injectable()
export class SubscriptionProductControllerService extends ControllerBase {
  protected resourceName = 'SubscriptionProduct';

  public singlePackageEnvironment = new BehaviorSubject<boolean>(false);

  public subscriptionProducts = new BehaviorSubject<SubscriptionProduct[]>([]);

  public getAll(): Observable<SubscriptionProducts | undefined> {
    return new Observable((observer) => {
      this.get<SubscriptionProducts>().subscribe((x) => {
        const products = x?.subscriptionProducts ?? [];

        this.singlePackageEnvironment.next(products.length === 1);
        this.subscriptionProducts.next(products);

        observer.next(x);
        observer.complete();
      });
    });
  }

  public getProduct(
    productId: string
  ): Observable<SubscriptionProduct | undefined> {
    return this.get([productId]);
  }
}
