import { Component, ElementRef, OnInit } from '@angular/core';
import { EnvironmentDetails, EnvironmentDetailsControllerService, SubscriptionSolver } from '@trade4sure/t4s-lib';
import { CustomerUserControllerService } from './modules/api-client/services/user/customer-user/customer-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends SubscriptionSolver implements OnInit {

  constructor(
    private customerUserService: CustomerUserControllerService,
    private environmentDetailsService: EnvironmentDetailsControllerService,
    private rootElement: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.customerUserService.fetchCurrentUser();
    this.environmentDetailsService.fetchEnvironment();

    this.subscriptions.push(
       // Primary colors are fetched from the environment and set in the site's body.
      this.environmentDetailsService.currentEnvironment
      .subscribe(environment => this.updateColorMap(environment))
    );
  }

  private updateColorMap(environment: EnvironmentDetails | undefined): void {
    const colorMap = environment?.colorMap ?? {};
    // Warning: don't use this method for adding default styling. Only in last case basis.
    if (colorMap != null) {
      for (const [name, value] of Object.entries(colorMap)) {
        document.body.style.setProperty(name, value)
      }
    }
  }
}
