import { Injectable } from '@angular/core';
import { RedirectButton } from '@trade4sure/t4s-lib';
import { Subject } from 'rxjs';

export type FilterType = string | boolean | number | string[] | number[];

export type OutputFilters = {
  [key: string]: FilterType;
};

export type Filter = {
  key: string;
  value: FilterType;
};

export type InputFilters = {
  [name: string]: Filter;
};

@Injectable()
export class HeaderService {
  // When the header should show a button to redirect to another page.
  public redirectButton = new Subject<RedirectButton | undefined>();

  // For the header to accept and show.
  public inputFilters = new Subject<InputFilters>();
  // For the header to send back to the component, so it can be processed into the overview.
  public outputFilters = new Subject<OutputFilters>();
  public currentFilter: string | undefined;
  public showSearchField = new Subject<boolean>();
  public showExportButton = new Subject<boolean>();

  public resetHeader(
    redirectButton?: RedirectButton,
    inputFilters?: InputFilters,
    currentFilter?: string,
    showSearchField = true,
    showExportButton = false
  ) {
    this.redirectButton.next(redirectButton);
    this.inputFilters.next(inputFilters ?? {});
    this.currentFilter = currentFilter;
    this.showSearchField.next(showSearchField);
    this.showExportButton.next(showExportButton);
  }

  public setExport(state: boolean) {
    this.showExportButton.next(state);
  }
}
