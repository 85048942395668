<div class="modal-main">
    <div class="modal-header">
        <span class="title">{{ title }}</span>
    </div>
    <div class="modal-body">
        <span *ngIf="!downgrade">
            Het spijt ons u te zien gaan.
            Na bevestiging zal uw pakket worden stopgezet aan het einde van uw looptijd.
            Dit is tot {{ user?.currentSubscriptionPurchase?.endAt | date:'dd-MM-yyyy' }}. Vanaf dat moment zullen al uw opinies vervallen, en zullen uw gegevens verwijderd worden.
        </span>
        <span *ngIf="downgrade">
            Weet u zeker dat u de toekomstige pakket wijziging wilt annuleren?
        </span>
    </div>
</div>
<div class="modal-footer">
    <button (click)="openChangePackageDetailsModal()"
        class="btn btn-outline-primary">
        {{ cancelButtonText }}
    </button>
    <t4s-save-button (clicked)="cancelCurrentPackage()"
        ngbAutoFocus
        customClass="btn btn-danger"
        [loading]="loading">
        {{ agreeButton.text }}
    </t4s-save-button>
</div>
