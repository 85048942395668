import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@node-packages/auth';
import {
  EnvironmentDetails,
  EnvironmentDetailsControllerService,
  EnvironmentSettingsInsuranceType,
  SubscriptionSolver,
  T4sDecodedToken,
} from '@trade4sure/t4s-lib';
import { zip } from 'rxjs';
import { ChangePackageDetailsModalComponent } from 'src/app/components/modals/change-package-details-modal/change-package-details-modal.component';
import { SupportModalComponent } from 'src/app/components/modals/support-modal/support-modal.component';
import { SubscriptionProduct } from 'src/app/modules/api-client/models/product/product';
import { CustomerUser } from 'src/app/modules/api-client/models/user/user';
import { SubscriptionProductControllerService } from 'src/app/modules/api-client/services/product/subscription-product-controller.service';
import { CustomerUserControllerService } from 'src/app/modules/api-client/services/user/customer-user/customer-user.service';

export interface NavigationItem {
  category: string;
  sideMenuTitle: string;
  icon: string;
  route: string;
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent extends SubscriptionSolver {
  public currentUser: CustomerUser | undefined;
  public environmentDetails: EnvironmentDetails | undefined;

  private navigationItems: NavigationItem[] = [];
  private subscriptionProducts: SubscriptionProduct[] = [];

  public get categories(): string[] {
    return [...new Set(this.navigationItems.map((x) => x.category))];
  }

  public get showCreditAmount(): boolean {
    return this.environmentDetails?.settings.insuranceType == EnvironmentSettingsInsuranceType.Default;
  }

  public get canUpgrade(): boolean {
    if (!this.currentUser || this.subscriptionProducts.length <= 0)
      return false;
    const index = this.subscriptionProducts.findIndex(
      (x) => x.id === this.currentUser?.currentSubscriptionPurchase?.productId
    );
    return index < this.subscriptionProducts.length - 1;
  }

  public get nextSubscription(): SubscriptionProduct | null {
    if (!this.currentUser || this.subscriptionProducts.length <= 0) return null;
    const index = this.subscriptionProducts.findIndex(
      (x) => x.id === this.currentUser?.currentSubscriptionPurchase?.productId
    );
    return index < this.subscriptionProducts.length - 1
      ? this.subscriptionProducts[index + 1]
      : null;
  }

  public filterNavigationItems() {
    this.navigationItems = this.navigationItems.filter((navigationItem) => {
      switch (true) {
        case !this.currentUser?.currentSubscriptionPurchase
          ?.amountOfDebtorScans &&
          navigationItem.sideMenuTitle === 'Debiteurenscan':
          return false;
        case this.environmentDetails?.settings.insuranceType ===
          EnvironmentSettingsInsuranceType.CombinedWithDebtorTest &&
          (navigationItem.sideMenuTitle === 'Debiteurenscan' ||
            navigationItem.sideMenuTitle === 'Debiteuren'):
          return false;
        default:
          return true;
      }
    });
  }

  public openChangePackageDetailsModal(): void {
    const modalRef = this.modalService.open(
      ChangePackageDetailsModalComponent,
      { centered: true }
    );
    modalRef.componentInstance.title = 'Wijzig pakket';
    modalRef.componentInstance.agreeButton = {
      text: this.currentUser?.currentSubscriptionPurchase?.isCancelled
        ? 'Pakket activeren'
        : 'Wijzigingen opslaan',
    };
  }

  public setNavigationItems() {
    this.route.routeConfig?.children?.forEach((route) => {
      if (route.data && route.path) {
        this.navigationItems.push({
          category: route.data['category'] ?? 'Services',
          sideMenuTitle: route.data['sideMenuTitle'] ?? '',
          icon: route.data['icon'] ?? '',
          route: route.path,
        });
      }
    });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService<T4sDecodedToken>,
    private customerUserService: CustomerUserControllerService,
    private environmentService: EnvironmentDetailsControllerService,
    private subscriptionProductService: SubscriptionProductControllerService,
    private modalService: NgbModal
  ) {
    super();
    this.setNavigationItems();

    this.subscriptionProductService
      .getAll()
      .subscribe(
        (x) =>
          (this.subscriptionProducts =
            x?.subscriptionProducts.sort((s1, s2) => s1.tier - s2.tier) ?? [])
      );

    this.subscriptions.push(
      zip(
        this.environmentService.currentEnvironment,
        this.customerUserService.currentUser
      ).subscribe(([environment, user]) => {
        this.currentUser = user;
        this.environmentDetails = environment;
        this.navigationItems = [];
        this.setNavigationItems();
        this.filterNavigationItems();
      })
    );
  }

  public getItems(category: string): NavigationItem[] {
    return this.navigationItems.filter((x) => x.category === category);
  }

  public isActivateRoute(route: string): boolean {
    return this.router.url.split('?')[0].endsWith(route);
  }

  public navigationClick(item: NavigationItem): void {
    this.router.navigate([item.route]);
  }

  public logout(): void {
    this.authenticationService.logout();
    this.customerUserService.clearCurrentUser();
  }

  public openSupportModal(): void {
    this.modalService.open(SupportModalComponent, { centered: true });
  }
}
