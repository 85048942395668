<div class="menu-container d-flex flex-column justify-content-between">
  <span>
    <t4s-logo customClass="w-65 mb-4"></t4s-logo>
    <div *ngFor="let category of categories; let i = index">
      <div class="category mt-2 mb-3">{{ category }}</div>
      <div
        *ngFor="let item of getItems(category)"
        class="title-container"
        [routerLink]="item.route"
        [ngClass]="{ active: isActivateRoute(item.route) }"
      >
        <span class="d-flex">
          <span class="material-symbols-outlined me-2">{{ item.icon }}</span>
          {{ item.sideMenuTitle }}
        </span>
      </div>
      <hr *ngIf="i < categories.length - 1" />
    </div>
    <div>
      <div class="category mt-2 mb-3">Support</div>
      <div class="title-container" (click)="openSupportModal()">
        <span class="d-flex">
          <span class="material-symbols-outlined me-2">support</span>
          Support
        </span>
      </div>
    </div>
  </span>
  <span>
    <div class="tests-container d-flex flex-column" *ngIf="showCreditAmount">
      <div class="d-flex justify-content-between">
        <span class="title">Verbruikte toetsingen</span>
        <t4s-tooltip
            title="Verbruikte toetsingen"
            description="Hieronder staat een overzicht met het aantal verbruikte toetsingen en dus ook het aantal te besteden credits.
            Daarnaast worden er ook credits gereserveerd indien er actieve - niet geannuleerde - opinies vanuit een vorige subscriptie zijn, die dus verlengd zullen worden.
            Het aantal gereserveerde credits is: {{ currentUser?.reservedCredits }}"
        ></t4s-tooltip>
      </div>
      <span *ngIf="canUpgrade" class="description mt-1">Meer toetsingen nodig?</span>
      <span *ngIf="canUpgrade" class="description">Wijzig naar {{ nextSubscription?.title ?? "" }}.</span>
      <t4s-progress-bar 
        [withText]="true" 
        [max]="currentUser?.currentSubscriptionPurchase?.amountOfDebtorTests ?? 0"
        [current]="(currentUser?.currentSubscriptionPurchase?.amountOfDebtorTests ?? 0) - (currentUser?.credits ?? 0)"
        class="me-4 mt-2"
      ></t4s-progress-bar>
      <a *ngIf="canUpgrade" (click)="openChangePackageDetailsModal()" class="link mt-2">Wijzigen</a>
    </div>
    <hr class="my-4" />
    <div class="d-flex mb-2 justify-content-between align-items-center">
      <a routerLink="/portal/profile" class="d-flex flex-grow-1">
        <t4s-profile-image [user]="currentUser"></t4s-profile-image>
        <div class="d-flex flex-column mx-2 flex-grow-1">
          <span class="name">{{ currentUser?.fullName }}</span>
          <span class="email">{{ currentUser?.email }}</span>
        </div>
      </a>
      <span (click)="logout()" class="material-symbols-outlined logout ms-auto">
        logout
      </span>
    </div>
  </span>
</div>
