import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProgressStepperComponent } from './progress-stepper/progress-stepper.component';
import { RouterModule } from "@angular/router";
import { ProgressStepperGuard } from "./services/stepper-guard.service";
import { ProgressStepperCommunicationService } from "./services/progress-stepper.service";
import { Trade4SureInputComponentsModule } from "@trade4sure/t4s-lib";
import { GraphicComponentsModule } from "../graphic/graphic.module";

@NgModule({
    declarations: [
        ProgressStepperComponent
    ],
    exports: [
        ProgressStepperComponent,
        Trade4SureInputComponentsModule
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        GraphicComponentsModule,
        Trade4SureInputComponentsModule
    ],
    providers: [
        ProgressStepperGuard,
        ProgressStepperCommunicationService,
    ]
})
export class InputComponentsModule { }
