import { Injectable } from "@angular/core";
import { ApiClientService, ControllerBase } from '@trade4sure/t4s-lib';
import { finalize, Observable } from "rxjs";
import { Order } from "../../models/order/order";
import { RegisterDto } from "../../models/registration/registration";
import { CustomerUserControllerService } from "../user/customer-user/customer-user.service";

@Injectable()
export class RegistrationControllerService extends ControllerBase {
  protected resourceName = 'Registration';

  constructor(protected override apiClient: ApiClientService,
    private customerUserService: CustomerUserControllerService) {
    super(apiClient);
  }

  public create(model: RegisterDto): Observable<Order | undefined> {
    return this.post<RegisterDto, Order>([], model)
    .pipe(
      finalize(() => this.customerUserService.fetchCurrentUser())
    );
  }
}
