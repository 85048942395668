import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  finalize,
  map,
  Observable,
  ReplaySubject,
} from 'rxjs';
import {
  ApiClientService,
  ModelBase,
  UserControllerBase,
} from '@trade4sure/t4s-lib';
import {
  CreateMandateBody,
  CustomerUser,
  RegisterUserDTO,
  UserAccount,
  UserAccountResponse,
  UserEmail,
  UserEmailResponse,
} from '../../../models/user/user';
import { FormGroup } from '@angular/forms';
import { OrderStatus } from '../../../models/order/order';

@Injectable({
  providedIn: 'root',
})
export class CustomerUserControllerService extends UserControllerBase<CustomerUser> {
  protected resourceName = 'CustomerUser';
  public hasPendingOrder = new BehaviorSubject<boolean>(false);

  constructor(protected override apiClient: ApiClientService) {
    super(apiClient);

    this.currentUser = this._currentUser.asObservable();

    this.currentUser.subscribe((user) => {
      const order = user?.currentSubscriptionPurchase?.order;

      this.hasPendingOrder.next(
        order != null && order.status === OrderStatus.InProgress
      );
    });
  }

  public fetchCurrentUser(): void {
    this.get<CustomerUser>().subscribe((user) => {
      this._currentUser.next(user);
    });
  }

  public clearCurrentUser(): void {
    this._currentUser.complete();
    this._currentUser = new ReplaySubject<CustomerUser | undefined>(1);
    this.currentUser = this._currentUser.asObservable();
  }

  public register(
    registerObject: RegisterUserDTO
  ): Observable<CustomerUser | undefined> {
    return this.post<RegisterUserDTO, CustomerUser>(['create'], registerObject);
  }

  public updateUser(
    customerUser: CustomerUser
  ): Observable<ModelBase | undefined> {
    return this.put<CustomerUser, ModelBase>(undefined, customerUser).pipe(
      finalize(() => this.fetchCurrentUser())
    );
  }

  public updateUserAccount(
    userAccount: UserAccount,
    formGroup?: FormGroup
  ): Observable<UserAccountResponse | undefined> {
    return this.put<UserAccount, UserAccountResponse>(
      ['account'],
      userAccount,
      formGroup
    ).pipe(finalize(() => this.fetchCurrentUser()));
  }

  public updateUserEmail(
    userAccount: UserEmail
  ): Observable<UserEmailResponse | undefined> {
    return this.put<UserEmail, UserEmailResponse>(
      ['account', 'email'],
      userAccount
    );
  }

  public requestKyc(): Observable<CustomerUser | undefined> {
    return this.post<object, CustomerUser>(['kyc']).pipe(
      map((user: CustomerUser | undefined) => {
        if (user) this._currentUser.next(user);
        return user;
      })
    );
  }

  public createMandate(
    body: CreateMandateBody,
    formGroup?: FormGroup
  ): Observable<CustomerUser | undefined> {
    return this.post<object, CustomerUser>(['mandate'], body, formGroup);
  }
}
