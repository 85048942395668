import { ModelBase } from "@trade4sure/t4s-lib";
import { DebtorTest } from "../debtor/debtor";
import { CreateFileResponse } from "../file/file";
import { Insurance } from "../insurance/insurance";

export enum ClaimStatus {
    Transferred = 0,
    Ended = 1
}

export const statusTranslations: Record<ClaimStatus, string> = {
    [ClaimStatus.Transferred]: 'Overgedragen',
    [ClaimStatus.Ended]: 'Afgerond',
};

export interface Claim extends ModelBase {
    status: ClaimStatus;
}

export interface InsuranceClaim extends Claim {
    debtorTestId: string;
    debtorTest: DebtorTest;
    insurances: Insurance[];
}

export const isInsuranceClaim = (claim: Claim): claim is InsuranceClaim => {
    return (<InsuranceClaim>claim).insurances !== undefined;
}

export interface ClaimListResponse extends ModelBase {
    insuranceClaims: InsuranceClaim[];
}

export interface CreateClaimDto {
    interestRate: number;
    productType?: string;
    note?: string;
    files: CreateFileResponse[];
}

export interface CreateInsuranceClaimDto extends CreateClaimDto {
    debtorTestId: string;
}

export interface CreateInsuranceClaimResponse extends InsuranceClaim {
    interestRate: number;
    createdAt: string;
}

export interface CreateClaimRequest {
    interestRate: number;
    productType?: string;
    note?: string;
    fileIds: string[];
}

export interface CreateInsuranceClaimRequest extends CreateClaimRequest {
    debtorTestId: string;
}