import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ProgressStepperBaseComponent, ProgressStepperModel } from "../base-components/stepper-base.component";

@Injectable()
export class ProgressStepperGuard<T extends ProgressStepperModel> implements CanDeactivate<ProgressStepperBaseComponent<T>> {

    canDeactivate(component: ProgressStepperBaseComponent<T>, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot | undefined): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if(!component) return true;
        return component.callbackBeforeRedirecting(nextState?.url ?? "");
    }
}