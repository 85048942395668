import { Injectable } from "@angular/core";
import { ControllerBase } from '@trade4sure/t4s-lib';
import { Observable } from "rxjs";
import { SingleDebtorTestProduct } from "../../models/product/product";

@Injectable()
export class SingleDebtorTestProductControllerService extends ControllerBase {
  protected resourceName = 'SingleDebtorTestProduct';

  public getProduct(countryCode: string): Observable<SingleDebtorTestProduct | undefined> {
    return this.get([],
      undefined,
      {
      params: {
        "countryCode": countryCode
      }
    });
  }
}