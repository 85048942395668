import { Injectable } from '@angular/core';
import { ApiClientService } from '@trade4sure/t4s-lib';
import { finalize, Observable } from 'rxjs';
import { DebtorScan, FileIdBody } from '../../models/debtor-scan/debtor-scan';
import { QueryControllerBase } from '../query-controller-base.service';
import { CustomerUserControllerService } from '../user/customer-user/customer-user.service';

@Injectable()
export class DebtorScanControllerService extends QueryControllerBase<DebtorScan> {
  protected resourceName = 'DebtorScan';

  constructor(private customerUserService: CustomerUserControllerService,
    protected override apiClient: ApiClientService) {
    super(apiClient);
  }

  public uploadDebtorScanFile(fileId: FileIdBody): Observable<DebtorScan | undefined> {
    return this.post<FileIdBody, DebtorScan>([], fileId).pipe(
      finalize(() => this.customerUserService.fetchCurrentUser())
    );
  }
}
