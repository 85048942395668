import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingIndicatorService } from '@trade4sure/t4s-lib';
import { CustomerUser } from 'src/app/modules/api-client/models/user/user';
import { OrderControllerService } from 'src/app/modules/api-client/services/order/order-controller.service';
import { CustomerUserControllerService } from 'src/app/modules/api-client/services/user/customer-user/customer-user.service';
import { CancelPackageFeedbackModalComponent } from '../cancel-package-feedback-modal/cancel-package-feedback-modal.component';
import { ChangePackageDetailsModalComponent } from '../change-package-details-modal/change-package-details-modal.component';
import { AgreeButton } from '../shared/interfaces/modals.interface';

@Component({
  selector: 'app-cancel-package-modal',
  templateUrl: './cancel-package-modal.component.html',
  styleUrls: [
    './cancel-package-modal.component.scss',
    '../shared/styles/modals.scss',
  ],
})
export class CancelPackageModalComponent extends LoadingIndicatorService {
  @Input() title = 'Weet u het zeker?';

  @Input() agreeButton: AgreeButton = {
    text: 'Ja',
  };
  
  @Input() cancelButtonText = 'Annuleren';

  @Input() downgrade = false;

  public user: CustomerUser | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private customerUserService: CustomerUserControllerService,
    private orderService: OrderControllerService
  ) {
    super();
    this.subscriptions.push(
      this.customerUserService.currentUser.subscribe((x) => {
        this.user = x;
      })
    );
  }

  public cancelCurrentPackage(): void {
    const id = this.downgrade ? this.user?.nextSubscriptionPurchase?.id : this.user?.currentSubscriptionPurchase?.id;
    const orderId = this.downgrade ? this.user?.nextSubscriptionPurchase?.orderId : this.user?.currentSubscriptionPurchase?.orderId;
    if (id && orderId) {
      this.executeLoadingCall(() => this.orderService.cancelOrder(orderId, id))
      .subscribe(() => {
        this.activeModal.dismiss();
        if(!this.downgrade) {
          this.modalService.open(CancelPackageFeedbackModalComponent, {
            centered: true,
          });
        }
      });
    }
  }

  public openChangePackageDetailsModal(): void {
    this.activeModal.dismiss();
    this.modalService.open(ChangePackageDetailsModalComponent, {
      centered: true,
    });
  }
}
