import { Injectable } from '@angular/core';
import { ControllerBase } from '@trade4sure/t4s-lib';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  AdvancedSearchRequest,
  AllianzCompaniesSearchResponse,
  CompaniesAdvancedSearchRequest,
} from '../../models/allianz/companies';
import { AllianzCountriesResponse } from '../../models/allianz/countries';

@Injectable()
export class AllianzControllerService extends ControllerBase {
  protected resourceName = 'Allianz';

  public readonly registerableCountries: Record<string, string> = {
    NL: 'Nederland',
    BE: 'België',
    LU: 'Luxemburg',
  };

  private countries = new BehaviorSubject<AllianzCountriesResponse | undefined>(
    undefined
  );

  public advancedSearch(
    request: AdvancedSearchRequest
  ): Observable<AllianzCompaniesSearchResponse | undefined> {
    const searchCriteria: CompaniesAdvancedSearchRequest = {
      pageSize: 10,
    };

    if (/^\d+$/.test(request.term) || request.nationalIdentifier) {
      searchCriteria.idTypeCode = 'KVK';
      searchCriteria.idValue = request.term;
    } else {
      searchCriteria.companyName = request.term;
    }

    if (request.city) searchCriteria.town = request.city;
    if (request.countryCode) searchCriteria.countryCode = request.countryCode;

    return this.post<
      CompaniesAdvancedSearchRequest,
      AllianzCompaniesSearchResponse
    >(['companies'], searchCriteria);
  }

  public getCountries(): Observable<AllianzCountriesResponse | undefined> {
    return new Observable((observer) => {
     

      // if (this.countries.value) {
      //   observer.next(this.countries.value);
      //   observer.complete();
      //   return;
      // }

      this.get<AllianzCountriesResponse>(['countries']).subscribe((x) => {
        this.countries.next(x);
        observer.next(x);
        observer.complete();
      });
    });
  }

  public getRegisterableCountries(): Observable<
    AllianzCountriesResponse | undefined
  > {
    return this.getCountries().pipe(
      map((response) => {
        if (response)
          response.countries = response.countries.filter((x) => x.canRegister);
        return response;
      })
    );
  }

  public getCountriesAsRecords(): Observable<Record<string, string>> {
    return this.getCountries().pipe(
      map((countries) => {
        if (!countries) return {} as Record<string, string>;
        return countries.countries.reduce((acc, val) => {
          acc[val.countryCode] = val.name;
          return acc;
        }, {} as Record<string, string>);
      })
    );
  }

  public getInsurancePossibleCountriesAsRecords(): Observable<
    Record<string, string>
  > {
    return this.getCountries().pipe(
      map((countries) => {
        if (!countries) return {} as Record<string, string>;
        return countries.countries.reduce((acc, val) => {
          if (val.insurancePossible) acc[val.countryCode] = val.name;
          return acc;
        }, {} as Record<string, string>);
      })
    );
  }

  public getDebtorTestPossibleCountriesAsRecords(): Observable<
    Record<string, string>
  > {
    return this.getCountries().pipe(
      map((countries) => {
        if (!countries) return {} as Record<string, string>;
        return countries.countries.reduce((acc, val) => {
          if (val.canRequestDebtorTest) acc[val.countryCode] = val.name;
          return acc;
        }, {} as Record<string, string>);
      })
    );
  }

  public getRegisterableCountriesAsRecords(): Observable<
    Record<string, string>
  > {
    return this.getRegisterableCountries().pipe(
      map((countries) => {
        if (!countries) return {} as Record<string, string>;
        return countries.countries.reduce((acc, val) => {
          acc[val.countryCode] = val.name;
          return acc;
        }, {} as Record<string, string>);
      })
    );
  }
}
