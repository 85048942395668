import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EnvironmentConfiguration, Trade4SureSharedModule } from '@trade4sure/t4s-lib';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreSiteComponentsModule } from './components/site/core/core-site-components.module';
import { ApiClientModule } from './modules/api-client/api-client.module';
import { CustomerUser } from './modules/api-client/models/user/user';
import { AuthenticationControllerService } from './modules/api-client/services/authentication/authentication-controller.service';
import { CustomerUserControllerService } from './modules/api-client/services/user/customer-user/customer-user.service';
import { errorResponseMap } from './_data/errorResponseMap';

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Trade4SureSharedModule.forRoot<AuthenticationControllerService, CustomerUser, CustomerUserControllerService>({
      afterLoginRoute: '/registration',
      loginRoute: '/login',
      apiBaseUrl: environment.apiBaseUrl,
      authRequests: [
        '/Identity/',
        '/CustomerUser/create',
        '/CustomerUser/account/email',
        '/Environment'
      ],
      showValidationErrors: environment.showValidationErrors,
      additionalRequestErrorMap: errorResponseMap,
      cdnStorageUrl: environment.cdnStorageUrl
    } as EnvironmentConfiguration,
    AuthenticationControllerService,
    CustomerUserControllerService),
    ApiClientModule,
    CoreSiteComponentsModule
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'nl'
   }],
  bootstrap: [AppComponent]
})
export class AppModule {}
