import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentDetails, EnvironmentDetailsControllerService, SubscriptionSolver } from '@trade4sure/t4s-lib';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss', '../shared/styles/modals.scss'],
})
export class SupportModalComponent extends SubscriptionSolver {
  public environment: EnvironmentDetails | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private environmentService: EnvironmentDetailsControllerService
  ) {
    super();
    this.subscriptions.push(
      this.environmentService.currentEnvironment
      .subscribe(x => {
        this.environment = x;
      })
    )
  }
}
