<div
  class="d-flex flex-column"
  [className]="
    (customerUserService.hasPendingOrder | async) ? '' : 'header-container'
  "
>
  <div *ngIf="customerUserService.hasPendingOrder | async" class="p-2">
    <ngb-alert type="warning" [dismissible]="false" class="mb-0">
      Uw betaling wordt verwerkt, tot dan kunt u nog geen gebruik maken van onze
      diensten.
    </ngb-alert>
  </div>
  <div
    class="p-2 d-flex"
    [className]="
      (customerUserService.hasPendingOrder | async) ? 'header-container' : ''
    "
  >
    <div class="d-flex flex-column w-100">
      <div class="d-flex justify-content-between align-items-center mb-32">
        <span class="title">{{ title }}</span>
        <div class="d-flex align-items-stretch">
          <button
            *ngIf="showExportButton"
            [class.exporting]="exportService.inProgress"
            [disabled]="exportService.inProgress"
            (click)="onExportClicked()"
            class="material-symbols-outlined"
          >
            download
          </button>
          <t4s-search-input
            *ngIf="showSearchField"
            [placeholder]="'Zoek'"
            (searchTerm)="search($event)"
          ></t4s-search-input>
          <a
            *ngIf="redirectButton"
            (click)="onRedirectButtonClick()"
            class="btn btn-primary ms-2 d-flex align-items-center px-3"
            [ngClass]="{ disabled: redirectButton.disabled }"
          >
            <span *ngIf="redirectButton.icon" class="material-symbols-outlined">
              {{ redirectButton.icon }}
            </span>
            {{ redirectButton.text }}
          </a>
        </div>
      </div>
      <div *ngIf="filters" class="d-flex">
        <t4s-tab-switcher
          [tabs]="filters"
          [activeTab]="activatedFilter"
          (tabChanged)="filter($event)"
        ></t4s-tab-switcher>
      </div>
    </div>
  </div>
</div>
