<div
  class="d-flex justify-content-between align-items-center header"
  style="position: relative"
>
  <t4s-logo
    customClass="header-logo py-2"
    style="height: 100%"
  ></t4s-logo>
  <a
    *ngIf="!isFinished && withCancel"
    (click)="cancelClicked()"
    class="cancel-btn"
  >
    <span class="material-icons vertical-align-middle">close</span>
    Annuleren
  </a>
</div>
<div *ngIf="withProgress" class="content d-flex">
  <div class="overview w-30 d-flex flex-column justify-content-between">
    <div class="d-flex flex-column">
      <app-step
        *ngFor="let route of routes; let i = index"
        [lastItem]="i >= routes.length - 1"
        [number]="i + 1"
        [text]="orderedRoutes[route]"
        [activated]="isActivated(route)"
        [done]="isDone(route)"
      ></app-step>
    </div>
    <img src="assets/allianz-trade.svg" />
  </div>
  <div class="page d-flex flex-column justify-content-between w-100">
    <span>
      <router-outlet></router-outlet>
    </span>
    <div
      *ngIf="withNavigation"
      class="d-flex align-items-center"
      [ngClass]="{
        'justify-content-between': previousRoute && nextRoute,
        'justify-content-end': !previousRoute && nextRoute
      }"
    >
      <button
        *ngIf="previousRoute"
        (click)="redirect(previousRoute)"
        class="btn btn-outline-primary"
      >
        Vorige
      </button>
      <t4s-save-button
        *ngIf="nextRoute"
        [loading]="isBeingSaved"
        [disabled]="!canBeSaved"
        customClass="btn btn-primary px-3 py-2"
        (clicked)="redirect(nextRoute)"
      >
        {{ lastRouteActivated ? "Betalen" : "Volgende" }}
        <span class="material-symbols-outlined vertical-align-middle">
          {{ lastRouteActivated ? "check_circle" : "arrow_circle_right" }}
        </span>
      </t4s-save-button>
    </div>
  </div>
</div>
<div *ngIf="!withProgress" class="content">
  <span>
    <router-outlet></router-outlet>
  </span>
</div>
