import { Injectable } from '@angular/core';
import { ToastService, ToastType } from '@trade4sure/t4s-lib';
import { DebtorTestControllerService } from '../../api-client/services/debtor/debtor-test-controller.service';
import { ClaimControllerService } from '../../api-client/services/claim/claim-controller.service';
import { InsuranceControllerService } from '../../api-client/services/insurance/insurance-controller.service';
import { QueryRequestBase } from '../../api-client/models/generic/query-request';
import { Observer } from 'rxjs';

export enum CurrentExportType {
  NONE,
  DEBTOR,
  CLAIM,
  INSURANCE,
}

@Injectable()
export class ExportService {
  constructor(
    private toastService: ToastService,
    private debtorTestService: DebtorTestControllerService,
    private claimService: ClaimControllerService,
    private insuranceService: InsuranceControllerService
  ) {}

  public inProgress = false;
  private exportType: CurrentExportType = CurrentExportType.NONE;
  private filters?: QueryRequestBase;

  public executeExport() {
    this.sendExportRequest();
  }

  public setFilters(filters: QueryRequestBase) {
    this.filters = filters;
  }

  public setExportType(type: CurrentExportType) {
    this.exportType = type;
  }

  private showSuccessToastMessage() {
    this.toastService.show({
      title: 'De export is succesvol afgerond',
      type: ToastType.Info,
    });
  }
  private showErrorToastMessage() {
    this.toastService.show({
      title:
        'Er ging iets fout tijdens het exporteren. Probeer opnieuw of neem contact op met ons.',
      type: ToastType.Error,
    });
  }

  private sendExportRequest() {
    this.inProgress = true;

    //Create the same handling for each type of export
    const serviceSubscription: Partial<Observer<unknown>> = {
      error: () => {
        this.showErrorToastMessage();
        this.inProgress = false;
      },
      next: (response: any) => {
        const blobObj = new Blob([response], {
          type: 'text/csv;charset=utf-8;',
        });
        const blobUrl = URL.createObjectURL(blobObj);
        window.location.replace(blobUrl);
        this.showSuccessToastMessage();
        this.inProgress = false;
      },
    };

    if (!this.filters) {
      this.showErrorToastMessage();
      this.inProgress = false;
      return;
    }

    switch (this.exportType) {
      case CurrentExportType.CLAIM:
        this.claimService.export(this.filters).subscribe(serviceSubscription);
        break;
      case CurrentExportType.DEBTOR:
        this.debtorTestService
          .export(this.filters)
          .subscribe(serviceSubscription);
        break;
      case CurrentExportType.INSURANCE:
        this.insuranceService
          .export(this.filters)
          .subscribe(serviceSubscription);
        break;
    }
  }
}
