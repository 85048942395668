import { ModelBase } from "@trade4sure/t4s-lib";
import { CustomerUser } from "../user/user";
import { Purchase } from "./purchase";

export enum OrderType {
    None = 0,
    Purchase = 1,
    Renewal = 2,
    Upgrade = 3,
    Downgrade = 4
}

export enum OrderStatus {
    None = 0,
    InProgress = 1,
    Finished = 2,
    Failed = 3,
    DebitCreated = 4
}

export interface Order extends ModelBase {
    id: string;
    customerId: string;
    customer: CustomerUser;
    paidAt: string;
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    email: string;
    invoiceEmail: string;
    iban: string;
    accountHolder: string;
    type: OrderType;
    status: OrderStatus;
    purchases: Purchase[];
    checkoutUrl: string;
}

export interface CreateOrderDto {
    productId: string;
    paymentIntervalInMonths?: number;
    country?: string;
    companyName?: string;
    paymentType: 'ideal' | 'sepadebit';
}

export interface RenewOrderBody {
    productId: string;
    paymentIntervalInMonths: number;
}

export interface CancelOrderFeedback {
    reason: number;
    description: string;
}

export interface CalculatePriceBody {
    productId: string;
    paymentInterval: number;
}

export interface CalculatePriceResponse extends ModelBase {
    oldPriceIncludingTax: number;
    newPriceIncludingTax: number;
    priceIncludingTax: number;
    taxFactor: number;
    newPrice: number;
    oldPrice: number;
    price: number;
}