import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-dots',
  templateUrl: './progress-dots.component.html',
  styleUrls: ['./progress-dots.component.scss']
})
export class ProgressDotsComponent {
  @Input() public max = 3;
  @Input() public current = 0;

  public get numbers(): number[] {
    return [...Array(this.max).keys()];
  }
}
