<div class="modal-main">
    <div class="modal-header">
      <span class="title">
        <span class="material-symbols-outlined support-icon me-1">support</span>
        Heeft u hulp nodig?
      </span>
    </div>
    <div class="modal-body">
        <span class="description">Heeft u een vraag of komt u ergens niet uit, neem dan contact met ons op.</span>
        <div class="contact-container">
            <span class="d-flex">
                <span class="material-icons icon me-2">call</span>
                <span class="icon-title">Bel ons</span>
            </span>
            <span class="call-times">{{ environment?.supportText ?? '' }}</span>
            <a class="link" [href]="'tel:' + environment?.phoneNumber">{{ environment?.phoneNumber ?? '' }}</a>
        </div>
        <div class="contact-container">
            <span class="d-flex">
                <span class="material-icons icon me-2">mail</span>
                <span class="icon-title">Mail ons</span>
            </span>
            <a class="link" [href]="'mailto:' + environment?.email">{{ environment?.email ?? '' }}</a>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <button 
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
    >
      Sluiten
    </button>
  </div>
  
