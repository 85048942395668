import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CustomerUserControllerService } from '../../api-client/services/user/customer-user/customer-user.service';
import { map } from 'rxjs';
import { ToastService, ToastType } from '@trade4sure/t4s-lib';
import { OrderStatus } from '../../api-client/models/order/order';

@Injectable({
  providedIn: 'root',
})
export class PendingOrderGuard implements CanActivate {
  constructor(
    private customerUserControllerService: CustomerUserControllerService,
    private toastService: ToastService
  ) {}

  canActivate() {
    return this.customerUserControllerService.currentUser.pipe(
      map((user) => {
        const order = user?.currentSubscriptionPurchase?.order;

        const hasPendingOrder =
          order != null && order.status === OrderStatus.InProgress;

        if (hasPendingOrder) {
          // toast
          this.toastService.show({
            title: 'Uw betaling wordt verwerkt',
            type: ToastType.Warning,
            description: 'U kunt nog geen gebruik maken van onze diensten.',
          });

          return false;
        }
        return true;
      })
    );
  }
}
