import { Injectable } from '@angular/core';
import { ApiClientService, ControllerBase } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import {
  QueryRequestBase,
  QueryResponseBase,
} from '../models/generic/query-request';

@Injectable()
export abstract class QueryControllerBase<T> extends ControllerBase {
  constructor(protected override apiClient: ApiClientService) {
    super(apiClient);
  }

  public list(
    filters: QueryRequestBase
  ): Observable<QueryResponseBase<T> | undefined> {
    return this.post<QueryRequestBase, QueryResponseBase<T>>(['list'], filters);
  }

  public export(filters: QueryRequestBase): Observable<string | undefined> {
    return this.postWithoutPipe<QueryRequestBase>(
      ['export'],
      filters
    );
  }
}
