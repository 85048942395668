import { Injectable } from '@angular/core';
import { ControllerBase } from '@trade4sure/t4s-lib';
import { map, Observable } from 'rxjs';
import {
  ClaimListResponse,
  ClaimStatus,
  InsuranceClaim,
} from '../../models/claim/claim';
import {
  QueryRequestBase,
  QueryResponseBase,
} from '../../models/generic/query-request';

@Injectable()
export class ClaimControllerService extends ControllerBase {
  protected resourceName = 'Claim';

  public list(
    filters: QueryRequestBase
  ): Observable<ClaimListResponse | undefined> {
    return this.post<QueryRequestBase, ClaimListResponse>(['list'], filters);
  }

  public export(
    filters: QueryRequestBase
  ): Observable<QueryResponseBase<ClaimListResponse> | undefined> {
    return this.post<QueryRequestBase, QueryResponseBase<ClaimListResponse>>(
      ['export'],
      filters
    );
  }
  public getActiveClaims(): Observable<InsuranceClaim[]> {
    return this.list({
      filterValues: {
        status: ClaimStatus[ClaimStatus.Transferred],
      },
    }).pipe(
      map((x) => {
        return x ? x.insuranceClaims : [];
      })
    );
  }
}
