<div class="modal-main">
    <div class="modal-header">
        <span class="title">{{ title }}</span>
    </div>
    <div class="modal-body">
        <span class="modal-description">
            Bij verhoging zal het resterende bedrag automatisch geincasseerd worden. 
            Dit zijn de kosten van het nieuwe pakket minus de kosten van uw huidige pakket. 
            De kosten worden in rekening gebracht op de volgende rekening: {{ user?.iban }}
        </span>
        <div class="d-flex package-title align-items-center">
            {{ currentSelectedPackage?.title }}
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex">
                <span class="vertical-align-middle material-symbols-outlined">check</span>
                <p class="information ms-2">Ontvang {{ currentSelectedPackage?.amountOfDebtorTests }} toetsingen per jaar.</p>
            </div>
            <div *ngIf="currentSelectedPackage?.amountOfDebtorScans"
                class="d-flex">
                <span class="vertical-align-middle material-symbols-outlined">check</span>
                <p class="information ms-2">Ontvang {{ currentSelectedPackage?.amountOfDebtorScans }}x per abonnementsjaar een debiteurenscan.</p>
            </div>
        </div>
        <hr>
        <span class="d-flex justify-content-between my-2">
            <span class="costs-title">{{ currentSelectedPackage?.title }} kosten per jaar</span>
            <span class="costs-amount">{{ upgradeCosts?.newPrice ?? 0 | customCurrency:'roundOnZero' }}</span>
        </span>
        <span class="d-flex justify-content-between my-2">
            <span class="costs-title">Kosten huidig pakket</span>
            <span class="costs-amount">- {{ upgradeCosts?.oldPrice ?? 0 | customCurrency:'roundOnZero' }}</span>
        </span>
        <span class="d-flex justify-content-between my-2">
            <span class="costs-title total">Uw kosten <small>(excl. BTW)</small></span>
            <span class="costs-amount total">{{ upgradeCosts?.price ?? 0 | customCurrency:'roundOnZero' }}</span>
        </span>
        <span class="d-flex justify-content-between my-2">
            <span class="costs-title total">Uw kosten <small>(incl. BTW)</small></span>
            <span class="costs-amount total">{{ upgradeCosts?.priceIncludingTax ?? 0 | customCurrency:'roundOnZero' }}</span>
        </span>
    </div>
</div>
<div class="modal-footer">
    <button (click)="activeModal.dismiss()"
        class="btn btn-outline-primary">
        {{ cancelButtonText }}
    </button>
    <t4s-save-button (clicked)="upgradePackage()"
        ngbAutoFocus
        [loading]="loading">
        {{ agreeButton.text }}
    </t4s-save-button>
</div>
