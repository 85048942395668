import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionSolver } from '@trade4sure/t4s-lib';
import { ProgressStepperModel } from '../base-components/stepper-base.component';
import { ProgressStepperCommunicationService } from '../services/progress-stepper.service';

@Component({
  selector: 'app-progress-stepper',
  templateUrl: './progress-stepper.component.html',
  styleUrls: ['./progress-stepper.component.scss'],
})
export class ProgressStepperComponent extends SubscriptionSolver {
  @Input() public orderedRoutes: Record<string, string> = {};
  @Input() public finishedRoute: string | undefined;
  @Input() public withProgress = true;
  @Input() public withNavigation = true;
  @Input() public withCancel = true;
  @Output() public cancelled = new EventEmitter();

  public canBeSaved = false;
  public isBeingSaved = false;

  public get routes(): string[] {
    return Object.keys(this.orderedRoutes);
  }

  public get path(): string | undefined {
    return this.routes.find((x) => this.urlEqualsRoute(x));
  }

  public get activatedRouteIndex(): number {
    return this.routes.findIndex((x) => x === this.path);
  }

  public get lastRouteActivated(): boolean {
    return this.path === this.routes[this.routes.length - 1];
  }

  public get previousRoute(): string | undefined {
    const activatedRouteIndex = this.activatedRouteIndex;
    return activatedRouteIndex - 1 >= 0
      ? this.routes[activatedRouteIndex - 1]
      : undefined;
  }

  public get nextRoute(): string | undefined {
    const activatedRouteIndex = this.activatedRouteIndex;
    return activatedRouteIndex + 1 <= this.routes.length - 1 &&
      activatedRouteIndex !== -1
      ? this.routes[activatedRouteIndex + 1]
      : this.isFinished
      ? undefined
      : this.finishedRoute;
  }

  public get isFinished(): boolean {
    if (!this.finishedRoute) return false;
    return this.router.url.includes(this.finishedRoute);
  }

  constructor(
    private router: Router,
    communicator: ProgressStepperCommunicationService<ProgressStepperModel>
  ) {
    super();
    this.subscriptions.push(
      communicator.canBeSaved$.subscribe((x) => {
        this.canBeSaved = x;
      }),
      communicator.isBeingSaved$.subscribe((x) => {
        this.isBeingSaved = x;
      })
    );
  }

  public isActivated(route: string): boolean {
    return route === this.path;
  }

  public isDone(route: string): boolean {
    if (this.isFinished) return true;
    if (!this.path) return false;

    const routes = this.routes;
    return (
      routes.findIndex((x) => x === route) <
      routes.findIndex((x) => x === this.path)
    );
  }

  public cancelClicked(): void {
    this.cancelled.emit();
  }

  public redirect(route: string): void {
    this.canBeSaved = false;
    this.router.navigate([route]);
  }

  private urlEqualsRoute(route: string): boolean {
    const url = this.router.url;
    const routeSegments = route.split('/').filter((x) => x.trim().length > 0);
    const urlSegments = url.split('/').filter((x) => x.trim().length > 0);

    if (routeSegments.length != urlSegments.length) return false;

    return routeSegments
      .filter((segment) => !segment.startsWith(':'))
      .every((segment) => url.includes(segment));
  }
}
