import { Injectable } from '@angular/core';
import { ModelBase, ApiClientService, ControllerBase } from '@trade4sure/t4s-lib';
import { finalize, Observable } from 'rxjs';
import {
  CalculatePriceBody,
  CalculatePriceResponse,
  CancelOrderFeedback,
  CreateOrderDto,
  Order,
  RenewOrderBody,
} from '../../models/order/order';
import { CustomerUserControllerService } from '../user/customer-user/customer-user.service';

@Injectable()
export class OrderControllerService extends ControllerBase {
  protected resourceName = 'Order';

  constructor(
    private customerUserService: CustomerUserControllerService,
    protected override apiClient: ApiClientService) {
    super(apiClient);
  }

  public create(order: CreateOrderDto): Observable<Order | undefined> {
    return this.post<CreateOrderDto, Order>([], order).pipe(
      finalize(() => this.customerUserService.fetchCurrentUser())
    );
  }

  public renewOrder(order: RenewOrderBody): Observable<Order | undefined> {
    return this.post<RenewOrderBody, Order>(['renew'], order).pipe(
      finalize(() => this.customerUserService.fetchCurrentUser())
    );
  }

  public cancelOrder(
    orderId: string,
    id: string
  ): Observable<ModelBase | undefined> {
    return this.put<undefined, ModelBase>([
      orderId,
      'SubscriptionPurchase',
      id,
      'cancel'
    ]).pipe(finalize(() => this.customerUserService.fetchCurrentUser()));
  }

  public reactivateOrder(
    orderId: string,
    id: string
  ): Observable<ModelBase | undefined> {
    return this.put<undefined, ModelBase>([
      orderId,
      'SubscriptionPurchase',
      id,
      'reactivate',
    ]).pipe(finalize(() => this.customerUserService.fetchCurrentUser()));
  }

  public cancelOrderFeedback(
    orderId: string,
    id: string,
    cancelOrderFeedback: CancelOrderFeedback
  ): Observable<ModelBase | undefined> {
    return this.put<CancelOrderFeedback, ModelBase>(
      [
        orderId,
        'SubscriptionPurchase',
        id,
        'cancel',
        'feedback',
      ],
      cancelOrderFeedback
    ).pipe(finalize(() => this.customerUserService.fetchCurrentUser()));
  }

  public calculatePrice(
    calculatePriceBody: CalculatePriceBody
  ): Observable<CalculatePriceResponse | undefined> {
    return this.post<CalculatePriceBody, CalculatePriceResponse>(
      ['costs'],
      calculatePriceBody
    );
  }
}
