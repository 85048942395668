<div class="modal-main">
    <div class="modal-header">
        <span class="title">{{ title }}</span>
    </div>
    <div class="modal-body">
        <span class="description">
            Het spijt ons u te zien gaan. 
            Wij zouden graag u feedback ontvangen waarom u uw pakket hebt opgezegd om onze service te verbeteren. 
        </span>
        <t4s-checkbox-group
            label="Waarom heeft u het pakket opgezegd?"
            [control]="form.controls.reason"
            [checkboxes]="reasonCheckboxes"
            [outputAs]="CheckboxGroupOutput.SumOfValues"
        >
        </t4s-checkbox-group>
        <t4s-text-area
            [control]="form.controls.description"
            placeholder="Vertel ons meer, zodat we ons aanbod kunnen verbeteren."
            [rows]="8"
        ></t4s-text-area>
    </div>
</div>
<div class="modal-footer">
    <button (click)="skipCancelPackageFeedback()"
        class="btn btn-outline-primary">
        {{ cancelButtonText }}
    </button>
    <t4s-save-button (clicked)="sendCancelPackageFeedback()"
        ngbAutoFocus
        [disabled]="!user && !form.dirty"
        [loading]="loading">
        {{ agreeButton.text }}
    </t4s-save-button>
</div>
