import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionSolver } from '@trade4sure/t4s-lib';
import { CustomerUser } from 'src/app/modules/api-client/models/user/user';
import { CustomerUserControllerService } from 'src/app/modules/api-client/services/user/customer-user/customer-user.service';
import { PackageChangeType } from '../shared/enums/modals.enum';
import { AgreeButton } from '../shared/interfaces/modals.interface';

@Component({
  selector: 'app-package-change-success-modal',
  templateUrl: './package-change-success-modal.component.html',
  styleUrls: ['./package-change-success-modal.component.scss', '../shared/styles/modals.scss']
})
export class PackageChangeSuccessModalComponent extends SubscriptionSolver {
  @Input() title = 'Weet u het zeker?';
  @Input() change = PackageChangeType.Unchanged;
  @Input() agreeButton: AgreeButton = {
    text: 'Ja'
  };
  @Input() cancelButtonText = 'Annuleren';

  public PackageChangeType = PackageChangeType;

  public user: CustomerUser | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private customerUserService: CustomerUserControllerService
  ){
    super();
    this.subscriptions.push(      
      this.customerUserService.currentUser
      .subscribe((x) => {
        this.user = x;
      })
    );
  }
}
