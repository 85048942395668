export enum ModalResponseType {
    Agreed = 'agreed',
    Cancelled = 'cancelled'
}

export enum OrderCancellationValue {
    None = 0,
    LittleUsage = 1 << 0,
    TooComplicated = 1 << 1,
    TooExpensive = 1 << 2,
    TooManyTechnicalIssues = 1 << 3,
    Other = 0
}

export enum OrderCancellationReason {
    None = 'none',
    LittleUsage = 'little-usage',
    TooComplicated = 'too-complicated',
    TooExpensive = 'too-expensive',
    TooManyTechnicalIssues = 'too-many-technical-issues',
    Other = 'other'
}

export enum PackageChangeType {
    Unchanged = 0,
    Upgrade = 1,
    Downgrade = 2
}