import { Injectable } from '@angular/core';
import { TokensResultModel as AuthTokensResultModel } from '@node-packages/auth';
import { ApiClientService, AuthenticationControllerBase, ModelBase } from '@trade4sure/t4s-lib';
import { finalize, Observable, map } from 'rxjs';
import { LoginPostBody } from '../../models/authentication/authentication';
import { TokensResultModel } from '../../models/authentication/tokensResultModel';
import { ActivateAccountDTO, SendResetLinkDTO, VerifyResetLinkDTO } from '../../models/user/user';
import { CustomerUserControllerService } from '../user/customer-user/customer-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationControllerService extends AuthenticationControllerBase {
  private resourceName = 'Identity';

  constructor(private apiClient: ApiClientService,
    private customerUserController: CustomerUserControllerService) {
    super();
  }

  public login<AuthTokensResultModel>(loginBody: LoginPostBody): Observable<AuthTokensResultModel> {
    return this.apiClient.postWithoutErrorHandling<LoginPostBody, TokensResultModel>(
      this.resourceName,
      ['connect', 'authorize'],
      loginBody
    ).pipe(
      map(x => x ? x as AuthTokensResultModel : x),
      finalize(() => {
        this.customerUserController.fetchCurrentUser()
      })
    );
  }

  public sendResetLink(resetLinkObject: SendResetLinkDTO): Observable<ModelBase | undefined> {
    return this.apiClient.post<SendResetLinkDTO, ModelBase>(
      this.resourceName,
      ['sendresetlink'],
      resetLinkObject
    );
  }

  public verifyResetLink(resetLinkObject: VerifyResetLinkDTO): Observable<ModelBase | undefined> {
    return this.apiClient.post<VerifyResetLinkDTO, ModelBase>(
      this.resourceName,
      ['verifyresetlink'],
      resetLinkObject
    );
  }

  public activateAccount(activateObject: ActivateAccountDTO): Observable<TokensResultModel | undefined> {
    return this.apiClient.post<ActivateAccountDTO, TokensResultModel>(
      this.resourceName,
      ['activate'],
      activateObject
    );
  }

  public access(accessToken: string): Observable<AuthTokensResultModel> {
    return this.apiClient.postWithoutErrorHandling<object, TokensResultModel>(
      this.resourceName,
      ['connect', 'token', 'access'],
      {},
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    ).pipe(
      finalize(() => this.customerUserController.fetchCurrentUser())
    );
  }

  public refresh(refreshToken: string): Observable<AuthTokensResultModel> {
    return this.apiClient.postWithoutErrorHandling<object, TokensResultModel>(
      this.resourceName,
      ['connect', 'token', 'refresh'],
      {},
      {
        headers: {
          authorization: `Bearer ${refreshToken}`,
        },
      }
    ).pipe(
      map(x => x as AuthTokensResultModel),
      finalize(() => this.customerUserController.fetchCurrentUser())
    );
  }
}
