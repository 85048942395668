import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreateInsuranceDto,
  GetCalculateInsuranceCostsDto,
  GetCalculateInsuranceCostsResponse,
  GetEmailNotificationDto,
  GetEmailNotificationResponse,
  GetInsuranceCostsDto,
  GetInsuranceCostsResponse,
  GetInsurancePeriodsResponse,
  Insurance,
} from '../../models/insurance/insurance';
import { QueryControllerBase } from '../query-controller-base.service';

@Injectable()
export class InsuranceControllerService extends QueryControllerBase<Insurance> {
  protected resourceName = 'Insurance';

  public getById(id: string): Observable<Insurance | undefined> {
    return this.get<Insurance>([id]);
  }

  public create(
    insurance: CreateInsuranceDto
  ): Observable<Insurance | undefined> {
    return this.post<CreateInsuranceDto, Insurance>([], insurance);
  }

  public unsubscribe(insurance: Insurance): Observable<Insurance | undefined> {
    return this.put<object, Insurance>([insurance.id, 'unsubscribe']);
  }

  public getCalculateInsuranceCosts(
    model: GetCalculateInsuranceCostsDto
  ): Observable<GetCalculateInsuranceCostsResponse | undefined> {
    return this.post<
      GetCalculateInsuranceCostsDto,
      GetCalculateInsuranceCostsResponse
    >(['calculate'], model);
  }

  public getInsuranceCosts(
    model: GetInsuranceCostsDto
  ): Observable<GetInsuranceCostsResponse | undefined> {
    return this.post<GetInsuranceCostsDto, GetInsuranceCostsResponse>(
      ['costs'],
      model
    );
  }

  public getInsurancePeriods(): Observable<
    GetInsurancePeriodsResponse | undefined
  > {
    return this.get<GetInsurancePeriodsResponse>(['periods']);
  }

  public getEmailNotification(
    model: GetEmailNotificationDto
  ): Observable<GetEmailNotificationResponse | undefined> {
    return this.put<GetEmailNotificationDto, GetEmailNotificationResponse>(
      ['email-notification'],
      model
    );
  }
}
