import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingIndicatorService } from '@trade4sure/t4s-lib';
import { CalculatePriceResponse } from 'src/app/modules/api-client/models/order/order';
import { PriceInfo, SubscriptionProduct } from 'src/app/modules/api-client/models/product/product';
import { CustomerUser } from 'src/app/modules/api-client/models/user/user';
import { OrderControllerService } from 'src/app/modules/api-client/services/order/order-controller.service';
import { CustomerUserControllerService } from 'src/app/modules/api-client/services/user/customer-user/customer-user.service';
import { PackageChangeSuccessModalComponent } from '../package-change-success-modal/package-change-success-modal.component';
import { PackageChangeType } from '../shared/enums/modals.enum';
import { AgreeButton } from '../shared/interfaces/modals.interface';

@Component({
  selector: 'app-change-package-confirmation-modal',
  templateUrl: './change-package-confirmation-modal.component.html',
  styleUrls: [
    './change-package-confirmation-modal.component.scss',
    '../shared/styles/modals.scss',
  ],
})
export class ChangePackageConfirmationModalComponent extends LoadingIndicatorService implements OnInit {
  @Input() currentSelectedPackage: SubscriptionProduct | undefined;
  @Input() paymentIntervalInMonths: number | undefined;

  public title = '';
  
  public cancelButtonText = 'Annuleren';

  public agreeButton: AgreeButton = {
    text: 'Wijzig pakket',
  };

  public change = PackageChangeType.Upgrade;
  
  public user: CustomerUser | undefined;

  public currentSelectedPriceInfo: PriceInfo | undefined;
  public upgradeCosts: CalculatePriceResponse | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private customerUserService: CustomerUserControllerService,
    private modalService: NgbModal,
    private orderService: OrderControllerService
  ) {
    super();

    this.subscriptions.push(
      this.customerUserService.currentUser.subscribe((x) => {
        this.user = x;
      })
    );
  }

  public upgradePackage(): void {
    this.executeLoadingCall(() => this.orderService.renewOrder({
      productId: this.currentSelectedPackage?.id ?? '',
      paymentIntervalInMonths: this.paymentIntervalInMonths ?? 1
    }))
    .subscribe((response) => {
      if(!response) return;
      this.activeModal.dismiss();
      const modalRef = this.modalService.open(
        PackageChangeSuccessModalComponent,
        { centered: true }
      );
      modalRef.componentInstance.title = 'Pakket succesvol bijgewerkt ';
      modalRef.componentInstance.change = this.change;
      modalRef.componentInstance.agreeButton = {
        text: 'Bedankt!',
      };
    });
  }

  ngOnInit(): void {
    this.currentSelectedPriceInfo = this.currentSelectedPackage?.prices.find(x => x.paymentIntervalInMonths == this.paymentIntervalInMonths);
    this.title = `Wijzig pakket naar ${this.currentSelectedPackage?.title}`;

    if(!this.currentSelectedPackage || !this.paymentIntervalInMonths) return;

    this.orderService.calculatePrice({
      productId: this.currentSelectedPackage.id,
      paymentInterval: this.paymentIntervalInMonths
    }).subscribe(x => this.upgradeCosts = x);
  }
}
