import { Injectable } from '@angular/core';
import { ApiClientService } from '@trade4sure/t4s-lib';
import { finalize, Observable } from 'rxjs';
import { CreateInsuranceClaimRequest, CreateInsuranceClaimResponse } from '../../models/claim/claim';
import { CreateDebtorTestDto, DebtorTest, UpdateDebtorTestDto } from '../../models/debtor/debtor';
import { QueryControllerBase } from '../query-controller-base.service';
import { CustomerUserControllerService } from '../user/customer-user/customer-user.service';

@Injectable()
export class DebtorTestControllerService extends QueryControllerBase<DebtorTest> {
  protected resourceName = 'DebtorTest';

  constructor(protected override apiClient: ApiClientService,
    private customerUserService: CustomerUserControllerService) {
    super(apiClient);
  }

  public getById(id: string): Observable<DebtorTest | undefined> {
    return this.get<DebtorTest>([id]);
  }

  public create(debtorTest: CreateDebtorTestDto): Observable<DebtorTest | undefined> {
    return this.post<CreateDebtorTestDto, DebtorTest>([], debtorTest)
    .pipe(
      finalize(() => this.customerUserService.fetchCurrentUser())
    );
  }

  public createClaim(claim: CreateInsuranceClaimRequest): Observable<CreateInsuranceClaimResponse | undefined> {
    return this.post<CreateInsuranceClaimRequest, CreateInsuranceClaimResponse>([claim.debtorTestId, 'claim'], claim);
  }

  public unsubscribe(debtorTest: DebtorTest): Observable<DebtorTest | undefined> {
    return this.put<object, DebtorTest>([debtorTest.id, 'unsubscribe']);
  }

  public subscribe(debtorTest: DebtorTest): Observable<DebtorTest | undefined> {
    return this.put<object, DebtorTest>([debtorTest.id], {
      unsubscribedAt: null
    } as UpdateDebtorTestDto);
  }
}
