<div class="modal-main">
    <div class="modal-header">
        <span class="title">{{ title }}</span>
    </div>
    <div class="modal-body">
        <span>
            Toch nog gebruik maken van onze diensten? Activeer dan uw pakket weer.
        </span>
    </div>
</div>
<div class="modal-footer">
    <button (click)="cancelPackageReactivation()"
        class="btn btn-outline-primary">
        {{ cancelButtonText }}
    </button>
    <t4s-save-button (clicked)="reactivatePackage()"
        ngbAutoFocus
        [loading]="loading">
        {{ agreeButton.text }}
    </t4s-save-button>
</div>
