<span class="circle"
    [ngClass]="{ 'activated-circle': isDoneOrActivated, 'done-circle': done }"
    [ngStyle]="{ 'border': borderSize + 'px solid' }">
    <p *ngIf="!done"
        class="vertical-align-middle"
        [ngStyle]="{ 'font-size': textSize + 'px' }">
        {{ number }}
    </p>
    <span *ngIf="done"
        class="material-symbols-outlined">
        done
    </span>
</span>