import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debtorScanText, debtorTestsText, getPriceInfoWithHighestInterval, LoadingIndicatorService } from '@trade4sure/t4s-lib';
import { SubscriptionProduct } from 'src/app/modules/api-client/models/product/product';
import { CustomerUser } from 'src/app/modules/api-client/models/user/user';
import { DebtorTestControllerService } from 'src/app/modules/api-client/services/debtor/debtor-test-controller.service';
import { OrderControllerService } from 'src/app/modules/api-client/services/order/order-controller.service';
import { SubscriptionProductControllerService } from 'src/app/modules/api-client/services/product/subscription-product-controller.service';
import { CustomerUserControllerService } from 'src/app/modules/api-client/services/user/customer-user/customer-user.service';
import { CancelPackageModalComponent } from '../cancel-package-modal/cancel-package-modal.component';
import { ChangePackageConfirmationModalComponent } from '../change-package-confirmation-modal/change-package-confirmation-modal.component';
import { PackageChangeSuccessModalComponent } from '../package-change-success-modal/package-change-success-modal.component';
import { ReactivatePackageModalComponent } from '../reactivate-package-modal/reactivate-package-modal.component';
import { PackageChangeType } from '../shared/enums/modals.enum';
import {
  AgreeButton,
  PackageChange
} from '../shared/interfaces/modals.interface';

@Component({
  selector: 'app-change-package-details-modal',
  templateUrl: './change-package-details-modal.component.html',
  styleUrls: [
    './change-package-details-modal.component.scss',
    '../shared/styles/modals.scss',
  ],
})
export class ChangePackageDetailsModalComponent extends LoadingIndicatorService {
  public title = 'Wijzig pakket';
  public agreeButton: AgreeButton = {
    text: 'Wijzigingen opslaan',
  };
  public cancelButtonText = 'Annuleren';
  public user: CustomerUser | undefined;
  public debtorTestsText = '';
  public debtorScanText = '';
  public showDebtorScanText = false;
  public PackageChangeType = PackageChangeType;
  public packageChange: PackageChange | null = null;
  public subscriptionProducts: SubscriptionProduct[] = [];
  public monthlyPrices = false;
  public canHaveMonthlyPrices = false;
  public hasMonthlyPrices = false;
  public currentSelectedPackage: SubscriptionProduct | undefined = undefined;

  private currentTier: number | undefined;
  private amountOfActiveDebtorTests = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private customerUserService: CustomerUserControllerService,
    private subscriptionProductsService: SubscriptionProductControllerService,
    private debtorTestService: DebtorTestControllerService,
    private modalService: NgbModal,
    private orderService: OrderControllerService
  ) {
    super();
    this.subscriptions.push(
      this.customerUserService.currentUser.subscribe((x) => {
        this.user = x;
        this.debtorScanText = debtorScanText(this.user);
        this.debtorTestsText = debtorTestsText(this.user);
        this.showDebtorScanText =
          (this.user?.currentSubscriptionPurchase?.subscriptionProduct
            ?.amountOfDebtorScans ?? 0) > 0
            ? true
            : false;
        this.monthlyPrices = this.getPreferredInterval() == 1;
        this.canHaveMonthlyPrices = this.user?.currentSubscriptionPurchase?.paymentIntervalInMonths == 1;

        if(!this.user?.currentSubscriptionPurchase?.productId) return;
        this.subscriptionProductsService.getProduct(this.user?.currentSubscriptionPurchase?.productId)
        .subscribe((x) => {
          this.currentTier = x?.tier;
        });
      })
    );

    this.subscriptionProductsService.getAll()
    .subscribe((x) => {
      this.subscriptionProducts = x?.subscriptionProducts ?? [];
      this.hasMonthlyPrices = this.subscriptionProducts.some(x => x.prices.some(y => y.paymentIntervalInMonths == 1));
    });

    this.debtorTestService.list({})
    .subscribe(x => this.amountOfActiveDebtorTests = x?.records.filter(y => !y.unsubscribedAt).length ?? 0);
  }

  public selectPackage(productId: string) {
    this.currentSelectedPackage = this.subscriptionProducts.find(
      (subscriptionProduct) => subscriptionProduct.id === productId
    );

    if (!this.currentSelectedPackage || !this.user?.currentSubscriptionPurchase || this.currentTier === undefined)
      return;
    
    if (this.currentSelectedPackage.id === this.user.currentSubscriptionPurchase.productId ||
      this.currentSelectedPackage.tier === this.currentTier) {
      this.packageChange = null;
      return;
    }

    if (this.currentSelectedPackage.tier > this.currentTier) {
      this.packageChange = {
        type: PackageChangeType.Upgrade,
        warning: false,
      };
      return;
    }

    if (this.currentSelectedPackage.tier < this.currentTier) {
      this.packageChange = {
        type: PackageChangeType.Downgrade,
        warning: this.currentSelectedPackage.amountOfDebtorTests < this.amountOfActiveDebtorTests,
      };
      return;
    }
  }

  public agree(): void {
    if (this.user?.currentSubscriptionPurchase?.isCancelled) {
      this.activeModal.dismiss();
      const modalRef = this.modalService.open(ReactivatePackageModalComponent, {
        centered: true,
      });
      modalRef.componentInstance.title = 'Uw pakket weer activeren';
      modalRef.componentInstance.agreeButton = {
        text: 'Pakket activeren',
      };
      return;
    }
    
    const priceInfo = getPriceInfoWithHighestInterval(this.currentSelectedPackage);
    const interval = this.monthlyPrices
      ? 1
      : priceInfo?.paymentIntervalInMonths ?? 12;

    if (this.packageChange?.type === PackageChangeType.Upgrade) {
      this.activeModal.dismiss();
      const modalRef = this.modalService.open(ChangePackageConfirmationModalComponent, {
        centered: true
      });
      modalRef.componentInstance.currentSelectedPackage = this.currentSelectedPackage;
      modalRef.componentInstance.paymentIntervalInMonths = interval;
      return;
    }

    this.executeLoadingCall(() => this.orderService.renewOrder({
      productId: this.currentSelectedPackage?.id ?? '',
      paymentIntervalInMonths: interval
    }))
    .subscribe((response) => {
      if(!response) return;
      this.activeModal.dismiss();
      const modalRef = this.modalService.open(PackageChangeSuccessModalComponent, {
        centered: true
      });
      modalRef.componentInstance.title = 'Pakket succesvol bijgewerkt ';
      modalRef.componentInstance.change = PackageChangeType.Downgrade;
      modalRef.componentInstance.agreeButton = {
        text: 'Bedankt!',
      };
    });
  }

  public getPreferredInterval(): number | undefined {
    const currentInterval = this.user?.currentSubscriptionPurchase?.paymentIntervalInMonths;
    return currentInterval == 1
      ? this.monthlyPrices
        ? 1
        : undefined
      : currentInterval;
  }

  public openCancelPackageModal(): void {
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(CancelPackageModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = 'Uw pakket opzeggen';
    modalRef.componentInstance.agreeButton = {
      text: 'Pakket opzeggen',
    };
  }
}
