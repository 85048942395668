import { Injectable } from '@angular/core';
import { ModelBase, Upload } from '@trade4sure/t4s-lib';
import { Observable } from 'rxjs';
import { CreateFileResponse } from '../../models/file/file';
import { ControllerBase } from '@trade4sure/t4s-lib';

@Injectable()
export class FileControllerService extends ControllerBase {
  protected resourceName = 'File';

  public create(file: File): Observable<Upload<CreateFileResponse>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.postWithProgress<FormData, CreateFileResponse>([], formData);
  }

  public deleteById(id: string): Observable<ModelBase | undefined> {
    return this.delete<ModelBase>([id]);
  }
}
