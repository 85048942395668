import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AllianzControllerService } from './services/allianz/allianz-controller.service';
import { ClaimControllerService } from './services/claim/claim-controller.service';
import { DebtorScanControllerService } from './services/debtor/debtor-scan-controller.service';
import { DebtorTestControllerService } from './services/debtor/debtor-test-controller.service';
import { FileControllerService } from './services/file/file-controller.service';
import { InsuranceControllerService } from './services/insurance/insurance-controller.service';
import { OrderControllerService } from './services/order/order-controller.service';
import { SingleDebtorTestProductControllerService } from './services/product/single-debtor-test-product-controller.service';
import { SubscriptionProductControllerService } from './services/product/subscription-product-controller.service';
import { RegistrationControllerService } from './services/registration/registration-controller.service';

@NgModule({
  declarations: [],
  providers: [
    SubscriptionProductControllerService,
    AllianzControllerService,
    OrderControllerService,
    DebtorTestControllerService,
    InsuranceControllerService,
    RegistrationControllerService,
    SingleDebtorTestProductControllerService,
    ClaimControllerService,
    FileControllerService,
    DebtorScanControllerService
  ],
  imports:[
    HttpClientModule
  ]
})
export class ApiClientModule {}
