<div class="modal-main">
    <div class="d-flex justify-content-center">
        <img class="success-shader" src="/assets/success-shader.svg" />
    </div>
    <div class="modal-header">
        <span class="title">{{ title }}</span>
    </div>
    <div class="modal-body">
        <span *ngIf="change === PackageChangeType.Downgrade">
            Uw pakket is succesvol bijgewerkt. 
            De wijzigingen zullen ingaan aan het einde van de looptijd. 
            Dit is {{ user?.currentSubscriptionPurchase?.endAt | date:'dd-MM-yyyy' }}. 
            Tot die tijd kunt u de wijzigigen altijd nog annuleren.
        </span>
        <span *ngIf="change === PackageChangeType.Upgrade">
            Uw pakket is succesvol bijgewerkt. 
            De nieuwe voorwaarden zullen direct in werking gaan.
        </span>
    </div>
</div>
<div class="modal-footer justify-content-end">
    <button (click)="this.activeModal.dismiss();"
        ngbAutoFocus
        class="btn btn-primary">
        {{ agreeButton.text }}
    </button>
</div>